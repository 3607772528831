<template>
  <div v-for="subitem in subItems" :key="subitem._id">
    <va-tree-node
      icon="fa-music"
      v-if="subitem.stats.chmod.file"
      @click="fetchFileInfo(subitem, $event)"
    >{{ subitem.name }}</va-tree-node>
    <va-tree-category
      :label="subitem.name"
      icon="fa-folder"
      v-else-if="subitem.stats.chmod.directory"
      @click="fetchSubInfo(subitem, $event)"
    >
      <listItem
        v-if="subitem.children && subitem.children.length > 0"
        :sub-items="subitem.children"
      ></listItem>
    </va-tree-category>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import listItem from '../file-explorer/listItem'
export default {
  name: 'listItem',
  props: {
    subItems: Array
  },
  components: {
    listItem
  },
  methods: {
    ...mapActions("mounts", { fetchFileList: "fetchFileList", setSelectedItem: 'setSelectedItem' }),
    fetchSubInfo(subitem, event) {
      this.setSelectedItem(null)
      event.stopPropagation()
      subitem.topLevel = false
      this.fetchFileList(subitem)
    },
    fetchFileInfo(item, event) {
      event.stopPropagation()
      this.setSelectedItem(item)
    },
  },
  computed: {
    ...mapState("mounts", {
      selectedItem: "selectedItem", selectedMount: "selectedMount"
    }),
  }
}
</script>

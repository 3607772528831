<template>
  <div class="dashboard">
    <div class="row row-equal">
      <div class="flex md7">
        <h1>{{ $t('pages.explorer.text.file-explorer') }}</h1>

        <h2>{{ $t('pages.explorer.text.mounts') }}</h2>

        <va-tree-root>
          <va-tree-category
            :label= "$t('pages.explorer.buttons.add-mount')"
            icon="fa-desktop"
            @click="showAddMountModal = !showAddMountModal"
          ></va-tree-category>

          <va-tree-category
            v-for="mount in mounts"
            :key="mount._id"
            :label="mount.name"
            icon="fa-desktop"
            @click="fetchInfo(mount)"
          >
            <listItem
              v-if="mount.children && mount.children.length > 0"
              :sub-items="mount.children"
            ></listItem>
            <div v-else>{{ $t('pages.explorer.text.empty') }}</div>
          </va-tree-category>
        </va-tree-root>
        <va-modal
          v-model="showAddMountModal"
          hide-default-actions
          without-transitions
        >
          <template #header>
            <h2>{{ $t('pages.explorer.buttons.add-mount') }}</h2>
          </template>
          <slot>
            <div>
              <va-form ref="newmount" @validation="validation = $event">
                <va-input
                  class="mb-4"
                  v-model="newMountName"
                  :label="$t('pages.explorer.text.name')"
                  :placeholder="$t('pages.explorer.text.name-placeholder')"
                  :rules="requiredRule"
                />
                <va-input
                  class="mb-4"
                  v-model="newMountPath"
                  :label="$t('pages.explorer.text.path')"
                  :placeholder="$t('pages.explorer.text.path-placeholder')"
                  :rules="requiredRule"
                />
                <va-select
                  v-model="newMountType"
                  :options="['target', 'source']"
                  :label="$t('pages.explorer.text.type')"
                  :placeholder="$t('pages.explorer.text.type-placeholder')"
                  :rules="requiredRule"
                />
              </va-form>
            </div>
          </slot>
          <template #footer>
            <va-button class="mr-4" @click="addMount()">{{ $t('pages.explorer.buttons.yes') }}</va-button>
            <va-button
              class="mr-4"
              @click="showAddMountModal = !showAddMountModal"
              >{{ $t('pages.explorer.buttons.cancel') }}</va-button
            >
          </template>
        </va-modal>
      </div>

      <div
        class="flex md5"
        v-if="selectedItem && Object.keys(selectedItem).length > 0"
      >
        <div class="row">
          <div class="flex md12">
            <va-card>
              <va-card-content>
                <div id="player"></div>
                <div v-if="showFileActions">
                  <va-button icon="edit" class="mr-4" @click="navigateToEdit()"
                    >{{ $t('pages.explorer.buttons.edit-file') }}</va-button
                  >
                  <va-button
                    icon="delete"
                    color="danger"
                    @click="showDeleteModal = !showDeleteModal"
                    class="mr-4"
                    >{{ $t('pages.explorer.buttons.delete-file') }}</va-button
                  >
                  <va-button
                    icon="download"
                    @click="downloadFile()"
                    :loading="isDownloading"
                    class="mr-4"
                    >{{ $t('pages.explorer.buttons.download-file') }}</va-button
                  >
                </div>
                <va-modal
                  v-model="showDeleteModal"
                  hide-default-actions
                  without-transitions
                >
                  <template #header>
                    <h2>{{ $t('pages.explorer.text.remove') }} {{ selectedItem.name }}</h2>
                  </template>
                  <slot>
                    <div>
                      {{ $t('pages.explorer.text.remove-description') }} {{ selectedItem.name }}
                    </div>
                  </slot>
                  <template #footer>
                    <va-button class="mr-4" @click="removeSelectedFile()"
                      >{{ $t('pages.explorer.buttons.yes') }}</va-button
                    >
                    <va-button
                      class="mr-4"
                      @click="showDeleteModal = !showDeleteModal"
                      >{{ $t('pages.explorer.buttons.cancel') }}</va-button
                    >
                  </template>
                </va-modal>
              </va-card-content>
            </va-card>
          </div>
        </div>
        <div class="row">
          <div class="flex md12">
            <va-card>
              <va-card-content>
                <ul>
                  <li>
                    <b>{{ selectedItem.name }}</b>
                  </li>
                  <li>{{ $t('pages.explorer.text.file-size') }}: {{ formatSize(selectedItem.stats.size) }}</li>
                  <li>Id: {{ selectedItem._id }}</li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </va-card-content>
            </va-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import listItem from "../file-explorer/listItem";
import videojs from "video.js";

const prettyBytes = require("pretty-bytes");
import NavigationRoutes from "../sidebar/NavigationRoutes";

export default {
  name: "fileexplorer",
  props: {
    showFileActions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    info: null,
    player: null,
    source: "",
    items: NavigationRoutes.routes,
    showDeleteModal: false,
    showAddMountModal: false,
    newMountType: null,
    newMountName: null,
    newMountPath: null,
    requiredRule: [(value) => (value && value.length > 0) || "$t('pages.explorer.text.required')"],
  }),
  components: {
    listItem,
  },
  methods: {
    addMount() {
      if (this.$refs.newmount.validate()) {
        this.addNewMount({
          path: this.newMountPath,
          type: this.newMountType,
          name: this.newMountName,
          provider: "local",
        }).then((response) => {
          alert(response)
        });
      }
    },
    navigateToEdit() {
      this.setSelectedFile(this.selectedItem);
      this.$router.push({ name: "editor" });
    },
    ...mapActions("files", {
      deleteFile: "deleteFile",
      downloadFileRequest: "downloadFileRequest",
      returnFileSource: "returnFileSource",
      setSelectedFile: "setSelectedFile",
    }),
    ...mapActions("mounts", {
      fetchFileList: "fetchFileList",
      setSelectedItem: "setSelectedItem",
      addNewMount: "addNewMount",
    }),
    downloadFile() {
      this.downloadFileRequest({
        file: this.selectedItem.name,
        path: this.selectedItem.path.replace("/" + this.selectedItem.name, ""),
        mount: this.selectedItem.mountId,
      });
    },
    removeSelectedFile() {
      this.deleteFile({
        file: this.selectedItem.name,
        path: this.selectedItem.path.replace("/" + this.selectedItem.name, ""),
        mount: this.selectedItem.mountId,
      });
      this.showDeleteModal = !this.showDeleteModal;
      this.setSelectedFile(null);
    },
    formatSize(size) {
      return prettyBytes(size);
    },
    fetchInfo(mount) {
      mount.topLevel = true;
      this.info = mount;

      this.fetchFileList(mount);
    },
    setSource() {
      if (this.selectedItem.isFile && this.selectedItem.source) {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
        if (!this.player) {
          setTimeout(() => {
            var video = document.createElement("video");
            video.setAttribute("id", "filePlayer");
            video.classList.add("video-js", "vjs-fluid");
            let playerElement = document.getElementById("player");
            playerElement.appendChild(video);
            this.player = videojs("filePlayer", {
              controls: true,
              sources: [
                {
                  type: "video/mp4",
                  src: this.source,
                },
              ],
            });

            this.player.load();
          }, 100);
        } else {
          this.player.src({
            type: "video/mp4",
            src: this.source,
          });
        }
      }
    },
  },
  computed: {
    ...mapState("mounts", {
      mounts: "mounts",
      selectedMount: "selectedMount",
      selectedItem: "selectedItem",
    }),
    ...mapState("files", {
      actionState: "actionState",
      isDownloading: "isDownloading",
    }),
  },
  watch: {
    selectedItem(value, oldValue) {
      if (value && value !== oldValue && value.isFile) {
        this.source = value.source;
        this.setSource();
      }
    },
    actionState(value, oldValue) {
      this.$moshaToast(
        {
          title: this.actionState.title,
          description: this.actionState.message,
        },
        { type: this.actionState.result, position: "top-center", timeout: 2000 }
      );
    },
  },
  mounted() {
    this.setSelectedItem(null);
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
  },
};
</script>

<style scoped>
#player {
  margin-bottom: 10px;
}
</style>
